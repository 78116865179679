import slugify from "slugify";
import * as moment from 'moment-timezone';
import { IBrandDetails } from "src/app/core/domain/brand.model";
import pluralize from 'pluralize'
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { IMeetingAvailability, METTING_AVAILABILITY } from "src/app/core/domain/meetings.model";

export const createSlug = (name) => {
  return slugify(name, {
    replacement: '-',  // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true,      // convert to lower case, defaults to `false`
    strict: true,     // strip special characters except replacement, defaults to `false`
    locale: 'vi',       // language code of the locale to use
    trim: true         // trim leading and trailing replacement chars, defaults to `true`
  });

  // const slug = name.split(' ').join('-').toLocaleLowerCase();
  // return slug;
}

export function generateTimeSlots(interval: number = 15): string[] {
  let times = [];
  let start = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  for (let i = 0; i < (24 * 60) / interval; i++) {
    times.push(start.format('HH:mm'));
    start.add(interval, 'minutes');
  }

  return times;
}

export function generateTimeSlotsWithAMPM(interval: number = 15): Array<{ label: string, value: string }> {
  let times = generateTimeSlots(interval);

  const mappedData = times.map((e) => {
    return { value: e, label: moment(e, 'HH:mm').format('hh:mm A') }
  })
  return mappedData;
}

/**
 * Input 23:23 or 02:10 in local time, returns uat time
 */
export function convertToUTC(time: string, format: string = 'HH:mm'): string {
  // create a moment object using the given local time
  let localTime = moment(time, format);

  // convert the local time moment to a UTC moment
  let utcTime = localTime.tz('UTC');

  // format the UTC time as a string and return
  return utcTime.format(format);
}

export function convertToLocalTime(time: string, format: string = 'HH:mm'): string {
  // create a moment object using the given UTC time and specifying that the input is in UTC
  let utcTime = moment.utc(time, format);

  // convert UTC time to local time
  let localTime = utcTime.local();

  // format the local time as a string and return
  return localTime.format(format);
}

/**
 * @param times Creates time slots array alsp converts from utc to local time
 */
export function createTimeSlots(times: any = 15): string[] {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeSlots: string[] = [];

  times.forEach((time: any) => {
    const format = 'HH:mm';
    let startTime = moment.utc(time.startTime, format);
    let endTime = moment.utc(time.endTime, format);
    if (time.endTime == '00:00') {
      endTime.add(1, 'day')
    }
    let currentTime = startTime;

    while (currentTime <= endTime) {
      const localTime = currentTime.tz(timeZone).format(format);
      timeSlots.push(localTime);
      currentTime = currentTime.add(15, 'minutes');
    }
  });

  return timeSlots;
}

export const isDisabledDateForMeeting = (date: NgbDate, calenderAvilablity: IMeetingAvailability, calendar: NgbCalendar, disabledDaysIndex: any[]) => {
  if (calenderAvilablity.dates?.length) {
    const monthPadded = date.month.toString().padStart(2, '0')
    const datePadded = date.day.toString().padStart(2, '0')
    const foundDate = calenderAvilablity.dates.find((e) => e.date === `${date.year}-${monthPadded}-${datePadded}`)
    if (foundDate) {
      if (foundDate.closed) {
        return true
      } else {
        return false
      }
    }
  }

  return (disabledDaysIndex.includes(calendar.getWeekday(new NgbDate(date.year, date.month, date.day))))
    ? true
    : false;
}

export const findNextAvailableDate = (calenderAvilablity: IMeetingAvailability, calendar: NgbCalendar, disabledDaysIndex: any[]) => {
  const today = new Date()
  const totalDays = moment().daysInMonth();
  const month = new Date(today).getMonth() + 1;
  const day = new Date(today).getDate()
  const datesToCheck = []
  for (let i = day; i <= totalDays; i++) {
    datesToCheck.push({
      month: month,
      day: i,
      year: today.getFullYear()
    })
  }

  const availableDateFound = datesToCheck.find((date) => {
    const isDisabled = isDisabledDateForMeeting(date, calenderAvilablity, calendar, disabledDaysIndex)
    return !isDisabled
  })
  console.log('datesToCheck', datesToCheck, availableDateFound)
  return availableDateFound
}


export const createLookingForOptionsFromBrandDetails = (brandDetails: IBrandDetails) => {
  const defaultSelect = brandDetails.features.looking_for_section ? false : true;
  const lookingForOptions = [{
    label: 'Fund Raising',
    value: 'fundraising',
    selected: defaultSelect,
    show: true
  },
  {
    label: 'Hiring',
    value: 'tech_hiring',
    selected: defaultSelect,
    show: brandDetails.features.jobs
  },
  {
    label: 'Market Access',
    value: 'customer_access',
    selected: defaultSelect,
    show: brandDetails.users.corporates
  },
  {
    label: 'Mentorship',
    value: 'mentorship',
    selected: defaultSelect,
    show: brandDetails.users.mentors
  },
  {
    label: 'Business Services',
    value: 'business_services',
    selected: defaultSelect,
    show: brandDetails.users.service_providers
  }];

  return lookingForOptions
}


export const isDateInCurrentWeek = (date) => {
  // Get the current date
  const currentDate = moment();

  // Get the start and end of the current week
  const startOfWeek = currentDate.clone().startOf('week');
  const endOfWeek = currentDate.clone().endOf('week');

  // Check if the provided date is within the current week
  return moment(date).isBetween(startOfWeek, endOfWeek, null, '[]');
};



export const getFavIconUrl = (domain) => {
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=128`
}

export const getYouTubeThumbnail = (url) => {
  let videoId = getIdFromUrl(url);
  return 'https://img.youtube.com/vi/' + videoId + '/maxresdefault.jpg';
}

export const getIdFromUrl = (url) => {
  let videoId;
  if (url.includes('youtu.be')) {
    videoId = url.split('/').pop();
  } else if (url.includes('youtube.com/embed')) {
    videoId = url.split('/').pop();
  } else { // Assuming it's a watch URL
    var urlObj = new URL(url);
    videoId = urlObj.searchParams.get('v');
  }
  return videoId
}

export const getEmbedUrl = (id) => {
  return `https://www.youtube.com/embed/${id}`
}

export function toSingular(plural = '') {
  return pluralize.singular(plural)
}

export function formatEnumStringForUI(inputString) {
  // Split the input string by underscores
  let words = inputString.split('_');

  // Capitalize the first letter of each word
  let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with spaces
  let formattedString = formattedWords.join(' ');

  return formattedString;
}





export const calculateSlots = async ({
  date,
  calendar,
  calenderAvilablity,
  brandDetails,
  timeSlots,
  dateAvailability
}) => {
  const dayIndex = calendar.getWeekday(new NgbDate(date.year, date.month, date.day))

  const dayInfo = calenderAvilablity.days.find((e) => e.dayIndex === dayIndex)
  console.log('update date', date, dayIndex, calenderAvilablity.days, { dayInfo })

  const month = ('' + date.month).length === 2 ? date.month : '0' + date.month
  const day = ('' + date.day).length === 2 ? date.day : '0' + date.day

  const dateAvailabilityFromUser = calenderAvilablity.dates
  const selectedDateFormatted = `${date.year}-${month}-${day}`
  const foundDateAvailabilityFromUser = dateAvailabilityFromUser.find((e) => e.date === selectedDateFormatted)

  const times = calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days ? dayInfo.times : []
  console.log('foundDateAvailabilityFromUser', foundDateAvailabilityFromUser, times, !foundDateAvailabilityFromUser?.times?.length)
  console.log('calenderAvilablity', calenderAvilablity)

  if ((!times?.[0]?.startTime || !times?.[0]?.endTime) && !foundDateAvailabilityFromUser?.times?.length) {
    timeSlots = generateTimeSlotsWithAMPM(brandDetails?.features?.meeting_time_slot_difference_in_mins);
  } else {
    const slots = createTimeSlots(foundDateAvailabilityFromUser?.times?.length ? foundDateAvailabilityFromUser?.times : times);
    console.log('slotess------', slots)
    timeSlots = slots.map((e) => {
      return { value: e, label: moment(e, 'HH:mm').format('hh:mm A') }
    })
  }

  // if (foundDateAvailabilityFromUser?.times?.length) {
  //   foundDateAvailabilityFromUser.times.forEach((d) => {
  //     const tF = d.startTime;
  //     const tT = d.endTime;
  //     const startIndex = timeSlots.findIndex((e) => e.value === tF);
  //     const endIndex = timeSlots.findIndex((e) => e.value === tT);
  //     if (startIndex >= 0 && endIndex >= 0) {
  //       const sliceI = (endIndex - startIndex) + 1
  //       timeSlots.splice(startIndex, sliceI)
  //     }
  //   })
  // }

  // const dateAvailability = await this.meetingService.getUsersAvailabilityByDate(uid, selectedDateFormatted).toPromise()

  dateAvailability.forEach((d) => {
    const tF = convertToLocalTime(d.timeFrom.split(':').slice(0, 2).join(":"))
    const tT = convertToLocalTime(d.timeTo.split(':').slice(0, 2).join(":"))

    const startIndex = timeSlots.findIndex((e) => e.value === tF);
    const endIndex = timeSlots.findIndex((e) => e.value === tT);

    if (startIndex >= 0 && endIndex >= 0) {
      const sliceI = (endIndex - startIndex) + 1
      timeSlots.splice(startIndex, sliceI)
    }
    console.log({ tF, tT, startIndex, endIndex })
  })

  return timeSlots
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
