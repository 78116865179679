export interface LoginCredentials {
  countryCode: string;
  mobileNumber: string;
  email?: string;
}

export interface VerifyOtpCredentials extends LoginCredentials {
  code: string;
  loginFrom?: 'modal' | 'form';
}

export interface RegisterCredentials extends LoginCredentials {

}

export interface Auth extends LoginCredentials {
  accessToken: string;
  userId: number,
  name: string,
  accountType: ACCOUNT_TYPE,
  accountRole: ACCOUNT_ROLE;
  email: string;
}

export interface AuthResponse {
  accessToken: string;
}

export enum ACCOUNT_TYPE {
  STARTUP = 'startup',
  INVESTOR = 'investor',
  OTHER = 'other',
  CORPORATE = 'corporate',
  PARTNER = 'partner',
  JOB_SEEKER = 'job_seeker',
  MENTOR = 'mentor',
  SERVICE_PROVIDER = 'service_provider',
  PROGRAM_OFFICE = 'program_office',
  INDIVIDUAL = 'individual',
}


export enum ACCOUNT_ROLE {
  FOUNDER = 'founder',
  CO_FOUNDER = 'co_founder',
  HIRING_MANAGER = 'hiring_manager',
  BUSINESS_HEAD = 'business_head'
}


export interface IAccessTokenDetails {
  userId: number;
  accountType: string;
  accountRole: string;
  startupId: number;
  iat: number;
  exp: number;
}
