import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SocketChatNotification, SocketNotificationType } from 'src/app/shared/constants/enum';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { ISocketChatNotification, ISocketNotification, ISocketTyping } from '../domain/sockets.model';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  url = '';
  lastChatRoom;
  notifications: Subject<ISocketNotification> = new Subject<ISocketNotification>();
  messages: Subject<ISocketChatNotification> = new Subject<ISocketChatNotification>();
  chatSub: Subscription;
  startTyping: Subject<ISocketTyping> = new Subject<ISocketTyping>();
  endTyping: Subject<ISocketTyping> = new Subject<ISocketTyping>();
  onlineUsers: string[] = []
  constructor(private socket: Socket,
    private toastAlertService: ToastAlertService, private router: Router) { }

  setUrl(url: string) {
    this.url = url;
    this.socket.disconnect()
    this.socket = new Socket({ url: url })
  }

  listenToRoom(id: string) {
    this.socket.emit('joinRoom', '' + id);
    this.listenTOEvents();
    return this.socket.fromEvent('fetch-count').pipe(map(data => data));
  }


  listenTOEvents() {
    Object.values(SocketNotificationType).forEach((v) => {
      this.socket.fromEvent(v).pipe(map(data => data)).subscribe((res: ISocketNotification) => {

        this.notifications.next(res);
        if (v === SocketNotificationType.MESSAGE_NOTIFICATION_RECEIVED && this.router.url.includes('conversations')) {
          return
        }

        this.toastAlertService.showHtmlToast(res.title, res.icon, res.url)
      })
    })
  }


  leaveRoom(id: string) {
    this.socket.emit('leaveRoom', '' + id)
  }

  leaveLastChatRoom() {
    if (this.chatSub) {
      this.chatSub.unsubscribe()
    }
    this.leaveRoom(this.lastChatRoom)
  }

  listenToChatRoom(id: string) {
    this.lastChatRoom = 'conversation_' + id;
    this.socket.emit('joinRoom', this.lastChatRoom);
    this.listenTOChatRooms();
  }

  listenTOChatRooms() {
    Object.values(SocketChatNotification).forEach((v) => {
      this.chatSub = this.socket.fromEvent(v).pipe(map(data => data)).subscribe((res: ISocketChatNotification) => {
        // console.log('resss socket event', res)
        this.messages.next(res);
      })
    })
  }

  emitToRoom(event, data) {
    this.socket.emit(event, data);
  }
  listenToTyping(event) {
    this.socket.fromEvent(event).pipe(map(data => data)).subscribe((res: ISocketTyping) => {
      if (res.type === 'start_typing') {
        this.startTyping.next(res);
      } else {
        this.endTyping.next(res);
      }
    });
  }
  listenToEndTyping(event) {
    this.socket.fromEvent(event).pipe(map(data => data)).subscribe((res: ISocketChatNotification) => {
      console.log('endTyping', res)
      // this.endTyping.next(res);
    });
  }

  listenToOnlineUsers(event) {
    this.socket.fromEvent(event).pipe(map(data => data)).subscribe((res: ISocketChatNotification) => {
      if (res) {
        this.onlineUsers = Object.values(res)
      }
      // this.endTyping.next(res);
    });
  }

  isUserOnline(userUUId: string) {
    return this.onlineUsers.includes(userUUId)
  }

}
