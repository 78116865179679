import { MentorProfileCompleteness, ServiceProviderProfileCompleteness } from "../../domain";
import { InvestorProfileDashBoardActions, InvestorProfileDashboardActionTypes } from "./individual-profile.dashboard.action";

export interface IndividualDashboardState {
  individualDashboard: any;
  individualDashboardLoading: boolean;
  individualDashboardError: string | null | string[];

  individualProfileCompleteness: MentorProfileCompleteness;
  individualProfileCompletenessLoading: boolean;
  individualProfileCompletenessError: string | null | string[];

  requestApproval: string;
  requestApprovalLoading: boolean;
  requestApprovalError: string;
}

export const initialState: IndividualDashboardState = {
  individualDashboard: null,
  individualDashboardLoading: false,
  individualDashboardError: "",


  individualProfileCompleteness: null,
  individualProfileCompletenessLoading: false,
  individualProfileCompletenessError: "",


  requestApproval: null,
  requestApprovalLoading: false,
  requestApprovalError: "",

};



function initInvestorDashboard(state: IndividualDashboardState = initialState): IndividualDashboardState {
  return {
    ...state,
    individualDashboardLoading: true,
    individualDashboardError: ""
  };
}

function investorDashboardSuccess(state: IndividualDashboardState = initialState, data: any): IndividualDashboardState {
  return {
    ...state,
    individualDashboard: data,
    individualDashboardLoading: false,
    individualDashboardError: ""
  }
}

function investorDashboardFailed(state: IndividualDashboardState = initialState, data: string): IndividualDashboardState {
  return {
    ...state,
    individualDashboard: null,
    individualDashboardLoading: false,
    individualDashboardError: data
  }
}

function initIndividualProfileCompleteness(state: IndividualDashboardState = initialState): IndividualDashboardState {
  return {
    ...state,
    individualProfileCompletenessLoading: true,
    individualProfileCompletenessError: ""
  };
}

function individualProfileCompletenessSuccess(state: IndividualDashboardState = initialState, data: MentorProfileCompleteness): IndividualDashboardState {
  return {
    ...state,
    individualProfileCompleteness: data,
    individualProfileCompletenessLoading: false,
    individualProfileCompletenessError: ""
  }
}

function individualProfileCompletenessFailed(state: IndividualDashboardState = initialState, data: string): IndividualDashboardState {
  return {
    ...state,
    individualProfileCompleteness: null,
    individualProfileCompletenessLoading: false,
    individualProfileCompletenessError: data
  }
}


function initRequestApproval(state: IndividualDashboardState = initialState): IndividualDashboardState {
  return {
    ...state,
    requestApprovalLoading: true,
    requestApprovalError: ""
  };
}

function requestApprovalSuccess(state: IndividualDashboardState = initialState, data: string): IndividualDashboardState {
  return {
    ...state,
    requestApproval: data,
    requestApprovalLoading: false,
    requestApprovalError: ""
  }
}

function requestApprovalFailed(state: IndividualDashboardState = initialState, data: string): IndividualDashboardState {
  return {
    ...state,
    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: data
  }
}

export function individualDashboardReducer(state: IndividualDashboardState = initialState, action: InvestorProfileDashBoardActions): IndividualDashboardState {
  switch (action.type) {

    // case InvestorProfileDashboardActionTypes.GetMentorDashboard:
    //   return initInvestorDashboard(state);

    // case InvestorProfileDashboardActionTypes.GetMentorDashboardSuccess:
    //   return investorDashboardSuccess(state, action.payload);

    // case InvestorProfileDashboardActionTypes.GetMentorDashboardFault:
    //   return investorDashboardFailed(state, action.payload);


    case InvestorProfileDashboardActionTypes.GetProfileCompleteness:
      return initIndividualProfileCompleteness(state);

    case InvestorProfileDashboardActionTypes.GetProfileCompletenessSuccess:
      return individualProfileCompletenessSuccess(state, action.payload);

    case InvestorProfileDashboardActionTypes.GetProfileCompletenessFault:
      return individualProfileCompletenessFailed(state, action.payload);


    case InvestorProfileDashboardActionTypes.SendRequestApproval:
      return initRequestApproval(state);

    case InvestorProfileDashboardActionTypes.SendRequestApprovalSuccess:
      return requestApprovalSuccess(state, action.payload);

    case InvestorProfileDashboardActionTypes.SendRequestApprovalFault:
      return requestApprovalFailed(state, action.payload);

    default:
      return state;
  }
}
