import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum InvestorProfileDashboardActionTypes {
  GetProfileCompleteness = "[InvestorProfile Dashboard] GetInvestorProfileProfileCompleteness",
  GetProfileCompletenessSuccess = "[InvestorProfile Dashboard] GetInvestorProfileProfileCompletenessSuccess",
  GetProfileCompletenessFault = "[InvestorProfile Dashboard] GetInvestorProfileProfileCompletenessFault",

  // Request Approval
  SendRequestApproval = '[InvestorProfile Dashboard] SendRequestApproval',
  SendRequestApprovalSuccess = "[InvestorProfile Dashboard] SendRequestApprovalSuccess",
  SendRequestApprovalFault = "[InvestorProfile Dashboard] SendRequestApprovalFault",

  // GetMentorDashboard = "[InvestorProfile Dashboard] GetMentorDashboard",
  // GetMentorDashboardSuccess = "[InvestorProfile Dashboard] GetMentorDashboardSuccess",
  // GetMentorDashboardFault = "[InvestorProfile Dashboard] GetMentorDashboardFault",

}


// export class GetMentorDashboard implements Action {
//   readonly type = InvestorProfileDashboardActionTypes.GetMentorDashboard;
// }

// export class GetMentorDashboardSuccess implements Action {
//   readonly type = InvestorProfileDashboardActionTypes.GetMentorDashboardSuccess;
//   constructor(public payload: any) { }
// }

// export class GetMentorDashboardFault implements Action {
//   readonly type = InvestorProfileDashboardActionTypes.GetMentorDashboardFault;
//   constructor(public payload: string) { }
// }


export class GetProfileCompleteness implements Action {
  readonly type = InvestorProfileDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = InvestorProfileDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = InvestorProfileDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
  readonly type = InvestorProfileDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
  readonly type = InvestorProfileDashboardActionTypes.SendRequestApprovalSuccess;
  constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
  readonly type = InvestorProfileDashboardActionTypes.SendRequestApprovalFault;
  constructor(public payload: string) { }
}

export type InvestorProfileDashBoardActions =
  // GetMentorDashboard
  // | GetMentorDashboardSuccess
  // | GetMentorDashboardFault
  | GetProfileCompleteness
  | GetProfileCompletenessSuccess
  | GetProfileCompletenessFault
  | SendRequestApproval
  | SendRequestApprovalSuccess
  | SendRequestApprovalFault;
