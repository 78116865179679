import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IProfileDetails, IProfileType } from 'src/app/core/domain/profile.model';
import { getProfileData, getProfileTypes } from 'src/app/core/state/profile';
import { ACCOUNT_TYPE_TO_DASHBOARD_UTL } from 'src/app/shared/constants/navlinks';
import { AuthService } from 'src/app/core/service';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { ACCOUNT_TYPE, InvestorTypes, OrganizationInformationResponseModel } from 'src/app/core/domain';
import * as InvestorsOrgInfoActionActions from "src/app/core/state/investors-organization-info/investors-organization-info.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import * as CorporateActions from "src/app/core/state/corporate-info/corporate-info.action";
import * as MentorActions from "src/app/core/state/mentor-info/mentor-info.action";
import * as ServiceProviderActions from "src/app/core/state/service-provider-info/service-provider-info.action";
import * as PartnerActions from "src/app/core/state/partner-info/partner-info.action";
import { getStartUpInfo } from 'src/app/core/state/startup';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { Router } from '@angular/router';
import { createSlug } from '../../utils/common-methods';
import { getCorporateInfo } from 'src/app/core/state/corporate-info';
import { getMentorInfo } from 'src/app/core/state/mentor-info';
import { getPartnerInfo } from 'src/app/core/state/partner-info';
import { getServiceProviderInfo } from 'src/app/core/state/service-provider-info';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeProfileTypeModalComponent } from '../change-profile-type-modal/change-profile-type-modal.component';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { SocketService } from 'src/app/core/service/socket.service';
import * as ProgramOfficeActions from "src/app/core/state/program-office-members-info/program-office-members-info.action";
import { getProgramOfficeInfo } from 'src/app/core/state/program-office-members-info';
import * as IndividualActions from "src/app/core/state/individual-profile-info/individual-profile-info.action";
import { getIndividualProfileInfo } from 'src/app/core/state/individual-profile-info';


@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements OnInit, OnDestroy {

  @Input() isPublicHeader = false;

  private destroyed$: Subject<void> = new Subject();
  s3LogoUrl: string = '';


  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;

  profilePageUrl = '';

  profileEditPageUrl = '';

  ACCOUNT_TYPE_TO_DASHBOARD_UTL = ACCOUNT_TYPE_TO_DASHBOARD_UTL;

  ACCOUNT_TYPE = ACCOUNT_TYPE;
  profileTypes: IProfileType[] = []
  brandDetails: Partial<IBrandDetails>;

  accountTypeLabels = {
    [ACCOUNT_TYPE.CORPORATE]: 'Corporate',
    [ACCOUNT_TYPE.INVESTOR]: 'Investor',
    [ACCOUNT_TYPE.JOB_SEEKER]: 'Job Seeker',
    [ACCOUNT_TYPE.MENTOR]: 'Mentor',
    [ACCOUNT_TYPE.OTHER]: 'Other',
    [ACCOUNT_TYPE.PARTNER]: 'Partner',
    [ACCOUNT_TYPE.SERVICE_PROVIDER]: 'Service Provider',
    [ACCOUNT_TYPE.STARTUP]: 'Startup',
    [ACCOUNT_TYPE.PROGRAM_OFFICE]: 'Program Office',
  }
  profileTypeModalOpen = false;

  constructor(
    private readonly store: Store,
    private authService: AuthService,
    private router: Router,
    public modal: NgbModal,
    public socketService: SocketService,
  ) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
  }


  ngOnInit(): void {
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
        this.createS3LogoUrl();
        this.getStartupOrProfileInformation();
      }
    })

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((bRes)=> {
      if(bRes) {
        this.brandDetails = bRes;
      }
    })

    this.store.pipe(select(getProfileTypes)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileTypes = res;
        if(!this.profileTypeModalOpen) {
          // this.profileTypeModalOpen = true
          // this.openChangeProfileModal()
        }
      }
    })

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; this.createS3LogoUrl(); }
    });
  }

  getStartupOrProfileInformation() {
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

      if (this.profileData.investorType === InvestorTypes.ORGANIZATION || this.profileData.investorType === InvestorTypes.SYNDICATE) {
        this.profileEditPageUrl = '/investors/edit/organization-details';
      } else if (this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
        this.profileEditPageUrl = '/investors/edit/individual-details';
      }
      this.store.dispatch(new InvestorsOrgInfoActionActions.GetInvestorsOrgInfo);

      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          this.profilePageUrl = `/investors/profile/` + `${response.data.uuid}/${createSlug(response.data.organizationName) || '-'}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.profileEditPageUrl = '/startups/complete-profile';
      this.store.dispatch(new StartupActions.GetStartUpInfo);

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/startups/profile/` + `${res.uuid}/${createSlug(res.companyName)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.profileEditPageUrl = '/corporates/edit/corporate-intro';
      this.store.dispatch(new CorporateActions.GetCorporateInfo);

      this.store.pipe(select(getCorporateInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/corporates/profile/` + `${res.data.uuid}/${createSlug(res.data.companyName)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.profileEditPageUrl = '/mentors/edit/mentor-information';
      this.store.dispatch(new MentorActions.GetMentorInfo);

      this.store.pipe(select(getMentorInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          console.log('getMentorInfogetMentorInfo')
          this.profilePageUrl = `/mentors/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.profileEditPageUrl = '/program-office-members/edit/program-office-member-information';
      this.store.dispatch(new ProgramOfficeActions.ProgramOfficeInfo);

      this.store.pipe(select(getProgramOfficeInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/program-office-members/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.profileEditPageUrl = '/service-provider/edit/service-provider-information';
      // TODO
      this.store.dispatch(new ServiceProviderActions.GetServiceProviderInfo);

      this.store.pipe(select(getServiceProviderInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          console.log('getMentorInfogetMentorInfo')
          this.profilePageUrl = `/service-provider/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {
      this.profileEditPageUrl = '/partners/edit/partner-information';
      this.store.dispatch(new PartnerActions.GetPartnerInfo);
      this.store.pipe(select(getPartnerInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/partners/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.OTHER) {
      this.profilePageUrl = `/search/startups`
    }else if (this.profileData.accountType === ACCOUNT_TYPE.INDIVIDUAL) {
      this.profileEditPageUrl = '/individuals/edit/individual-information';
      this.store.dispatch(new IndividualActions.GetIndividualProfileInfo);

      this.store.pipe(select(getIndividualProfileInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/individuals/edit/individual-information`
        }
      });

    }
  }

  createS3LogoUrl = () => {
    if (this.globalSettings?.imgKitUrl && this.profileData?.avatar) {
      this.s3LogoUrl = this.profileData?.avatar + '?tr=w-100,h-100,cm-pad_resize';;
    } else {
      this.s3LogoUrl = '';
    }
  }

  handleLogout() {
    this.authService.logout();
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openChangeProfileModal ( ){
    const modal = this.modal.open(ChangeProfileTypeModalComponent,  { centered: true, size: 'md' })

    modal.closed.subscribe((res) => {
      if (res) {

      }
    })

  }
}
