import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE, CorporateProfileCompleteness, InvestorProfileCompleteness, MentorProfileCompleteness, OrganizationInformationResponseModel, ServiceProviderProfileCompleteness, StartupProfileCompleteness } from 'src/app/core/domain';
import { IBrandDetails, SideBarColors } from 'src/app/core/domain/brand.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { AuthService } from 'src/app/core/service';
import { ProfileService } from 'src/app/core/service/profile.service';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { getNotificationsCount } from 'src/app/core/state/notifications';
import { NotificationsCount } from 'src/app/core/state/notifications/notifications.model';
import { getProfileData } from 'src/app/core/state/profile';
import { getStartUpInfo } from 'src/app/core/state/startup';
import { getNavMenusByUserType, INavMenuItem } from 'src/app/shared/constants/navMenus';
import * as InvestorsOrgInfoActionActions from "src/app/core/state/investors-organization-info/investors-organization-info.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import * as CorporateActions from "src/app/core/state/corporate-info/corporate-info.action";
import { OffCanvasMenu, OffcanvasSidebarComponent } from 'src/app/shared/common-components/offcanvas-sidebar/offcanvas-sidebar.component';
import Swal from 'sweetalert2';
import { ChangeProfileTypeModalComponent } from 'src/app/shared/common-components/change-profile-type-modal/change-profile-type-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getCorporateInfo } from 'src/app/core/state/corporate-info';

import * as PartnerActions from "src/app/core/state/partner-info/partner-info.action";

import * as MentorActions from "src/app/core/state/mentor-info/mentor-info.action";
import * as IndividualActions from "src/app/core/state/individual-profile-info/individual-profile-info.action";
import * as ServiceProviderActions from "src/app/core/state/service-provider-info/service-provider-info.action";
import { getMentorInfo } from 'src/app/core/state/mentor-info';
import { getServiceProviderInfo } from 'src/app/core/state/service-provider-info';
import { getPartnerInfo } from 'src/app/core/state/partner-info';
import { SocketService } from 'src/app/core/service/socket.service';
import { getProgramOfficeInfo } from 'src/app/core/state/program-office-members-info';
import * as ProgramOfficeActions from "src/app/core/state/program-office-members-info/program-office-members-info.action";
import { createSlug } from 'src/app/shared/utils/common-methods';
import { getIndividualProfileInfo } from 'src/app/core/state/individual-profile-info';
import { MetricsService } from 'src/app/core/service/metrics.service';

@Component({
  selector: 'app-public-layout-sidebar',
  templateUrl: './public-layout-sidebar.component.html',
  styleUrls: ['./public-layout-sidebar.component.scss']
})
export class PublicLayoutSidebarComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  brandDetails: Partial<IBrandDetails>;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  public globalSettings$: Observable<IGlobalSettings>;
  public notificationsCount$: Observable<NotificationsCount>;
  globalSettings: IGlobalSettings;
  notificationsCount: NotificationsCount;
  navItems = getNavMenusByUserType(ACCOUNT_TYPE.OTHER, {});
  profilePageUrl = '';
  selectedNavItem: INavMenuItem = undefined;
  s3LogoUrl: string = '';
  navMenuList: Array<OffCanvasMenu>;
  @ViewChild(OffcanvasSidebarComponent) offcanvasSidebarComponent: OffcanvasSidebarComponent;
  ACCOUNT_TYPE = ACCOUNT_TYPE
  mode: 'light' | 'dark' = 'dark'

  showMessagesAndConnectionTab: boolean = false;
  showSwitchProfileBtn: boolean = false;
  profileCompleteness: StartupProfileCompleteness;
  pendingMetricsCount;

  constructor(
    private readonly store: Store,
    private authService: AuthService,
    private router: Router,
    public profileService: ProfileService,
    public modal: NgbModal,
    private socketService: SocketService,
    private metricsService: MetricsService
  ) {

    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.notificationsCount$ = this.store.pipe(select(getNotificationsCount));
  }

  ngOnInit(): void {

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.setNotificationsCount();
        this.brandDetails = res


        // this.validateRouteFeature();
        this.mode = res.features.sidebar_color === SideBarColors.DARK ? 'dark' : 'light'


        this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
          this.profileData = res;

          setTimeout(() => {
            this.socketService.listenToOnlineUsers('online_users')
            if(this.profileData) {
              this.socketService.emitToRoom('online_users', {
                userUUID: this.profileData.uuid
              })
            }
          }, 1000)



          let hideMessagesBtnGroupFrom = [
            ACCOUNT_TYPE.JOB_SEEKER,
            ACCOUNT_TYPE.PARTNER,
            ACCOUNT_TYPE.OTHER,
            ACCOUNT_TYPE.INDIVIDUAL,
          ];
          if (hideMessagesBtnGroupFrom.includes(this.profileData?.accountType)) {
            this.showMessagesAndConnectionTab = false;
            this.showSwitchProfileBtn = false;
          } else {
            this.showMessagesAndConnectionTab = true;
            this.showSwitchProfileBtn = true;
          }
          this.processRouteInfo()
          if (res) {
            this.createS3LogoUrl();
            this.getStartupOrProfileInformation();
          } else {
            this.profileData = undefined;
          }




          setTimeout(() => {
            this.profileService.getProfileCompleteness()
          }, 1500)
          this.profileService.profileCompleteness$
            .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe((profileCompleteness) => {
              if (profileCompleteness) {
                this.profileCompleteness = profileCompleteness as any ;
                this.processRouteInfo()

                if(this.profileData?.accountType === ACCOUNT_TYPE.STARTUP && this.brandDetails?.features?.growth_metrics && this.profileCompleteness?.isApproved){
                  this.fetchMatrics()
                }

              }
            })
        })
      }
    })

  }

  fetchMatrics() {
    this.metricsService.getMetrics().subscribe((res) => {
      // this.mapMatricsData(res)
      if(res) {
        this.pendingMetricsCount = Object.values(res).filter((e:any)=>!e?.length).length
        console.log('pendingMetricsCount',this.pendingMetricsCount)
      }
    })
  }

  processRouteInfo() {
    let NavItemsVisible = getNavMenusByUserType(this.profileData?.accountType, this.brandDetails as IBrandDetails, 'sidebar',this.profileData, this.profileCompleteness);

    this.navItems = NavItemsVisible;
    // .filter((e) => !e.isInMore);

    this.setNavBasedOnProfile()
    // const dashKey = this.navItems.find((e) => {
    //   return e.featureKey === 'show_dashboard'
    // })
    // if (!this.brandDetails.features['show_dashboard'] && dashKey) {
    //   this.navItems.unshift({
    //     title: 'Application Form',
    //     id: 'Application Form',
    //     iconClass: 'bi bi-list-check',
    //     route: '/startups/edit/startup-information',
    //     children: [],
    //     // children: [{
    //     //   title: 'Application Form',
    //     //   route: '/startups/edit/startup-information'
    //     // }],
    //   })
    // }

    // this.itemsInMore = NavItemsVisible.filter((item) => item.isInMore && (!item.featureKey || this.brandDetails.features[item.featureKey]));
    this.getMobileViewNavMenus(NavItemsVisible)
    this.setDefaultSelectedItem();

  }


  setNavBasedOnProfile() {
    const dashKey = this.navItems.find((e) => {
      return e.featureKey === 'show_dashboard'
    })
    // TODO: edit button condition from disable_startup_profile_update
    if (!this.brandDetails.features['show_dashboard'] && dashKey) {
      const alreadyThere = this.navItems.findIndex((e)=>e.id === 'Application Form')
      if(!this.isEditProfileDisabled) {
        if(alreadyThere <0) {
          this.navItems.unshift({
            title: 'Application Form',
            id: 'Application Form',
            route: '/startups/edit/startup-information',
            children: [],
            iconClass: 'bi bi-house'
            // children: [{
            //   title: 'Application Form',
            //   route: '/startups/edit/startup-information'
            // }],
          })
        }else{
          this.checkAndRedirectOnFormSubmitForEditDisable()
        }
      }else{
        if(alreadyThere >=0) {
          this.navItems.splice(alreadyThere,1)
        }
        this.checkAndRedirectOnFormSubmitForEditDisable()
      }
    }
  }

  checkAndRedirectOnFormSubmitForEditDisable() {
    if(this.router.url.includes('/startups/edit')) {
      this.router.navigate(['/'])
    }
  }

  get isEditProfileDisabled() {
    const backdoorLogin = localStorage.getItem('logged-in-from-backdoor');
    return this.brandDetails?.features?.disable_startup_profile_update && backdoorLogin !== 'true' && this.profileCompleteness?.approvalStatus === 'submitted'
  }

  setDefaultSelectedItem() {
    const selectedItem = this.navItems.find((navItem) => {
      const routeSelected = this.router.url.includes(navItem.route);
      const isChildSelected = navItem.children.find((subItem) => {
        if (this.router.url === '/calender/events' && subItem.route === '/calender') {
          return false
        }
        return this.router.url.includes(subItem.route)
      });


      return isChildSelected || routeSelected;
    })
    // if (selectedItem) {
    this.selectedNavItem = selectedItem;

    if (selectedItem?.children?.length) {
      // this.onSelectNavItem()
      this.itemShown(selectedItem.title)
    }
    // }
    // this.validateRouteFeature();
  }


  validateRouteFeature() {
    if (this.selectedNavItem) {

      if (!this.selectedNavItem.featureKey || this.brandDetails?.features?.[this.selectedNavItem.featureKey]) {
        // everything is fine here
      } else {
        setTimeout(() => {
          if (this.selectedNavItem.featureKey === 'show_dashboard') {
            this.router.navigate(['/startups/edit/startup-information']);
            return
          }
          this.router.navigate(['/errors/404']);
        })
      }

    } else {
      this.router.navigate(['/errors/404']);
    }
  }


  setNotificationsCount(): void {
    this.notificationsCount$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.notificationsCount = res; }
    });
  }


  getStartupOrProfileInformation() {
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
      this.store.dispatch(new InvestorsOrgInfoActionActions.GetInvestorsOrgInfo);

      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          this.profilePageUrl = `/investors/profile/` + `${response.data.uuid}/${createSlug(response.data.organizationName)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupActions.GetStartUpInfo);

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/startups/profile/` + `${res.uuid}/${createSlug(res.companyName)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.dispatch(new CorporateActions.GetCorporateInfo);

      this.store.pipe(select(getCorporateInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/corporates/profile/` + `${res.data.uuid}/${createSlug(res.data.companyName)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.dispatch(new MentorActions.GetMentorInfo);

      this.store.pipe(select(getMentorInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/mentors/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });

    }  else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.store.dispatch(new ProgramOfficeActions.ProgramOfficeInfo);

      this.store.pipe(select(getProgramOfficeInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/program-office-members/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.dispatch(new ServiceProviderActions.GetServiceProviderInfo);

      this.store.pipe(select(getServiceProviderInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/service-provider/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {
      this.store.dispatch(new PartnerActions.GetPartnerInfo);
      this.store.pipe(select(getPartnerInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/partners/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.OTHER) {
      this.profilePageUrl = `/search/startups`
    } else if (this.profileData.accountType === ACCOUNT_TYPE.INDIVIDUAL) {
      this.store.dispatch(new IndividualActions.GetIndividualProfileInfo);

      this.store.pipe(select(getIndividualProfileInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/individuals/edit/individual-information`
        }
      });
    }
  }

  // createSlug = (name) => {
  //   const slug = name.split(' ').join('-').toLocaleLowerCase();
  //   return slug;
  // }

  createS3LogoUrl = () => {
    if (this.globalSettings?.s3Url && this.profileData?.avatar) {
      this.s3LogoUrl = this.globalSettings.imgKitUrl + this.profileData?.avatar;
    } else {
      this.s3LogoUrl = '';
    }
  }

  handleLogout() {
    this.authService.logout();
  }

  onSelectNavItem(item: INavMenuItem, parent?, acc?) {
    this.selectedNavItem = parent || item;

    console.log('profilePageUrl', this.profilePageUrl)
    if (item.id === "my-profile") {
      this.router.navigate([this.profilePageUrl]);
      return;
      // item.route == this.profilePageUrl;
    } else {
      this.router.navigate([item.route], {
        skipLocationChange: false
      });
    }
    // if (this.selectedNavItem.children?.[0]) {
    //   const redirectUrl = this.selectedNavItem.children[0].route;
    //   this.router.navigate([redirectUrl]);
    // }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  extractURL(item) {
    this.selectedNavItem = item;
    return item.route
    // if (this.selectedNavItem.children?.[0]) {
    //   const redirectUrl = this.selectedNavItem.children[0].route;
    //   return redirectUrl
    // }
  }

  getMobileViewNavMenus(navItems) {
    this.navMenuList = navItems.map((item) => {
      return {
        id: 1,
        menu: item.title,
        link: this.extractURL(item),
        haveSubmenu: false,
        subMenuList: [],
        haveExternalLink: false
      }
    })
  }

  isItemSelected(item: INavMenuItem) {
    if (!this.selectedNavItem) {
      return false
    }
    const isSelected = this.selectedNavItem?.id === item.id
    const isChildSelected = item.children?.find((e) => e.id === this.selectedNavItem?.id)

    return isSelected || isChildSelected
  }
  isChildItemSelected(item: INavMenuItem) {
    const isChildSelected = this.selectedNavItem?.children?.find((e) => e.id === item?.id)
    if (!isChildSelected) {
      return
    }
    const routeSelected = this.router.url.includes(item.route);
    if (this.router.url === '/calender/events' && item.route === '/calender') {
      return false
    }
    // if(item.id === 'tickets') {
    //   console.log('tickets isSelected', isChildSelected, this.selectedNavItem.title)
    // }
    return routeSelected
  }

  open() {
    this.offcanvasSidebarComponent.openNav();
  }

  handleLogoutWithConfirm() {
    Swal.fire({
      title: 'Logout',
      text: 'Are you sure you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.handleLogout();
      }
    });
  }

  expandedItems = []

  itemShown(e) {
    this.expandedItems.push(e)
  }
  itemHidden(e) {
    this.expandedItems = this.expandedItems.filter((p) => p !== e)
  }
  isExpanded(id) {
    return this.expandedItems.includes(id)
  }

  handleMenuClick() {
    const profileMenu = document.getElementById('kt_header_user_menu_toggle_profile_toggle');
    if (profileMenu) {
      profileMenu.click()
    }
  }

  get isLimitedAccess() {
    return this.profileCompleteness?.isRejected && this.brandDetails?.features?.limited_access && this.profileCompleteness?.approvalStatus === 'limited_access'
  }

  openChangeProfileModal() {
    const modal = this.modal.open(ChangeProfileTypeModalComponent, { centered: true, size: 'md' })

    modal.closed.subscribe((res) => {
      if (res) {

      }
    })
  }





}
