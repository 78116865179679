import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { ServiceProviderProfileCompleteness } from "../../domain";
import * as InvestorProfileDashboardActions from "./individual-profile.dashboard.action";
import { InvestorProfileDashboardActionTypes } from './individual-profile.dashboard.action';
import { MentorService } from "../../service/mentors.service";
import { IndividualProfileService } from "../../service/individual-profile.service";

@Injectable()
export class InvestorProfileDashboardEffect {

  // getMentorDashboard$ = createEffect(() => this.actions$.pipe(
  //   ofType<MentorDashboardActions.GetMentorDashboard>(InvestorProfileDashboardActionTypes.GetMentorDashboard),
  //   exhaustMap(() => this.mentorService.getMentorDashboard().pipe(
  //     map((data: any) => new MentorDashboardActions.GetMentorDashboardSuccess(data)),
  //     catchError((err: HttpErrorResponse) => of(new MentorDashboardActions.GetMentorDashboardFault(err.error.message)))
  //   ))
  // ))


  getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
    ofType<InvestorProfileDashboardActions.GetProfileCompleteness>(InvestorProfileDashboardActionTypes.GetProfileCompleteness),
    map((action: InvestorProfileDashboardActions.GetProfileCompleteness) => action),
    exhaustMap(() => this.mentorService.getInvididualProfileCompleteness().pipe(
      mergeMap((data: ServiceProviderProfileCompleteness) => [
        new InvestorProfileDashboardActions.GetProfileCompletenessSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new InvestorProfileDashboardActions.GetProfileCompletenessFault(err.error.message)))
    ))
  ));

  // sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
  //   ofType<InvestorProfileDashboardActions.SendRequestApproval>(InvestorProfileDashboardActionTypes.SendRequestApproval),
  //   map((action: InvestorProfileDashboardActions.SendRequestApproval) => action),
  //   exhaustMap(() => this.mentorService.sendApprovalRequest().pipe(
  //     mergeMap((data: string) => [
  //       new InvestorProfileDashboardActions.SendRequestApprovalSuccess(data),
  //     ]),
  //     catchError((err: HttpErrorResponse) => of(new InvestorProfileDashboardActions.SendRequestApprovalFault(err.error.message)))
  //   )),
  // ));

  constructor(
    private actions$: Actions,
    private mentorService: IndividualProfileService
  ) { }
}
