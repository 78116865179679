import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../../../environments/environment";
import { AuthEffect } from "./auth/auth.effect";
import { FaqEffect } from "./faq/faq.effect";
import { GlobalEffect } from "./global/global.effect";
import {InvestorsOrgInfoEffect} from './investors-organization-info/investors-organization-info.effect';
import { GlossaryEffect } from "./glossary/glossary.effect";
import { reducers } from "./index";
import { StartUpEffect } from "./startup/startup.effect";
import { SignUpEffect } from "./sign-up/sign-up.effect";
import { ProfileEffect } from "./profile/profile.effect";
import { InvestorDashboardEffect } from "./investor-dashboard/investor.dashboard.effect";
import { StartupDashboardEffect } from "./startup-dashboard/startup.dashboard.effects";
import { StartupCompareEffect } from "./startup-compare/startup.compare.effects";
import { InvestorCompareEffect } from "./investor-compare/investor.compare.effects";
import {StartUpKitEffect} from './start-up-kit/start-up-kit.effect';
import { PitchEffect} from './pitch-deck/pitch-deck.effect'
import { NotificationsEffect } from "./notifications/notifications.effect";
import { CorporateInfoEffect } from "./corporate-info/corporate-info.effect";
import { CorporateDashboardEffect } from "./corporate-dashboard/corporate.dashboard.effect";
import { PartnersEffect } from "./partners/partners.effects";
import { PartnerInfoEffect } from "./partner-info/partner-info.effect";
import { AdsEffect } from "./ads/ads.effect";
import { JobsEffect } from "./jobs/jobs.effects";
import { ChallengesEffect } from "./challenges/challenges.effects";
import { MentorDashboardEffect } from "./mentors-dashboard/mentors.dashboard.effect";
import { MentorInfoEffect } from "./mentor-info/mentor-info.effect";
import { ServiceProviderInfoEffect } from "./service-provider-info/service-provider-info.effect";
import { ServiceProviderDashboardEffect } from "./service-provider-dashboard/service-provider.dashboard.effect";
import { FiltersEffect } from "./filters/filters.effect";
import { FeedEffect } from "./feed/feed.effect";
import { InvestorProfileDashboardEffect } from "./individual-profile-dashboard/individual-profile.dashboard.effect";
import { IndividualProfileInfoEffect } from "./individual-profile-info/individual-profile-info.effect";

import { ProgramOfficeDashboardEffect } from "./program-office-dashboard/program-office.dashboard.effect";
import { ProgramOfficeInfoEffect } from "./program-office-members-info/program-office-members-info.effect";

const EFFECTS = [
    IndividualProfileInfoEffect,
    InvestorProfileDashboardEffect,
    AuthEffect,
    FaqEffect,
    StartUpEffect,
    GlossaryEffect,
    GlobalEffect,
    InvestorsOrgInfoEffect,
    SignUpEffect,
    ProfileEffect,
    InvestorDashboardEffect,
    StartupDashboardEffect,
    StartupCompareEffect,
    InvestorCompareEffect,
    StartUpKitEffect,
    PitchEffect,
    NotificationsEffect,
    CorporateInfoEffect,
    CorporateDashboardEffect,
    PartnersEffect,
    PartnerInfoEffect,
    AdsEffect,
    JobsEffect,
    ChallengesEffect,
    MentorDashboardEffect,
    MentorInfoEffect,
    ServiceProviderDashboardEffect,
    ServiceProviderInfoEffect,
    FiltersEffect,
    FeedEffect,
    ProgramOfficeDashboardEffect,
    ProgramOfficeInfoEffect
];

const MODULES = [
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(EFFECTS),
    StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        logOnly: environment.production, // Restrict extension to log-only mode
      }),
];

@NgModule({
    imports: [MODULES],
    declarations: []
})
export class StateModule {
}
